import React from 'react';
import { Tag } from '@angellist/adapt';
import { formatTitle } from '../../helpers/utils';

type TagIntent =
  | 'neutral'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'draft'
  | 'new'
  | 'other'
  | 'inProgress'
  | 'pending'
  | 'complete'
  | 'postComplete'
  | 'canceled';

type Props = {
  as?: 'div' | 'span';
  label?: string;
  status?: string;
  intent?: TagIntent;
  emphasis?: boolean;
  icon?: any;
  whiteSpace?:
    | 'inherit'
    | 'initial'
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap'
    | undefined;
};

const TAG_INTENT_STATUSES: { [key: string]: string[] } = {
  draft: [
    'draft',
    'no_vested',
    'not_delivered',
    'not_submitted',
    'not_yet_started',
    'unissued',
  ],
  new: [],
  neutral: [
    'closedbycustomer',
    'invited',
    'suspectedfraud',
    'created',
    'auditor',
    'clicked',
    'company_live',
    'irs_number',
    'live',
    'not_seen',
    'offer_portal_manager',
    'open',
    'opened',
    'physical',
    'state_filings',
    'stock_issuance',
    'tax_id_number',
    'vesting',
    'virtual',
  ],
  other: ['clearing', 'viewer', 'expired', 'forfeited'],
  info: [],
  success: [
    'approved',
    'editor',
    'lawyer_approved',
    'seen',
    'submitted',
    'succeeded',
    'success',
    'surrendered',
    'uploaded',
  ],
  warning: [
    'awaiting_documents',
    'awaiting_esigns',
    'awaiting_funds',
    'awaiting_signature',
    'awaitingbackimage',
    'awaitingdocuments',
    'awaitingfrontimage',
    'awaitingimages',
    'needs_co_founder_signatures',
    'owner',
    'recommended',
  ],
  danger: [
    'denied',
    'inactive',
    'invalid',
    'priority',
    'required',
    'returned',
    'stolen',
    'forfeited',
  ],
  inProgress: ['in_progress', 'in_review', 'inprogress', 'processing'],
  pending: [
    'pending',
    'pending_acceptance',
    'pending_approval',
    'pending_issuance',
    'pending_payment',
    'pending_review',
    'pendingreview',
    'requires_company_signature',
  ],
  complete: [
    'complete',
    'completed',
    'delivered',
    'outstanding',
    'raising',
    'externally_executed',
    'fund_recieved',
    'funds_received',
    'sent',
    'signed',
    'vested',
    'active',
    'yes',
    'amendment_complete',
  ],
  postComplete: [
    'closed',
    'ended',
    'esop_manager',
    'executed',
    'fully_exercised',
    'repurchased',
    'exercised',
    'partially_exercised',
    'early_exercised',
  ],
  canceled: [
    'cancelled',
    'closedbycustomer',
    'failed',
    'frozen',
    'lost',
    'rejected',
  ],
};

const tagStatusMapping: { [key: string]: TagIntent } = {};

Object.keys(TAG_INTENT_STATUSES).forEach((intent: TagIntent) => {
  TAG_INTENT_STATUSES[intent].forEach((status) => {
    tagStatusMapping[status] = intent;
  });
});

export const STATUS_TAG_INTENT_MAPPING = tagStatusMapping;

export const getStatusTagProps = (
  status?: string,
  label?: string,
  intent?: TagIntent,
) => {
  const tagIntent =
    intent || STATUS_TAG_INTENT_MAPPING[status.toLowerCase()] || 'neutral';
  const tagLabel = label || formatTitle(status);

  return {
    intent: tagIntent,
    label: tagLabel,
  };
};

const StatusTag = (props: Props) => {
  const { status, intent, label, whiteSpace, ...rest } = props;
  if (status || label) {
    const { label: tagLabel, intent: tagIntent } = getStatusTagProps(
      status,
      label,
      intent,
    );

    return (
      <Tag intent={tagIntent} whiteSpace={whiteSpace || 'nowrap'} {...rest}>
        {tagLabel}
      </Tag>
    );
  }

  return null;
};

export default StatusTag;
