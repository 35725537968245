export const STRICTLY_US_STATES = {
  DE: 'Delaware (DE)',
  CA: 'California (CA)',
  TX: 'Texas (TX)',
  NY: 'New York (NY)',
  FL: 'Florida (FL)',
  WA: 'Washington (WA)',
  IL: 'Illinois (IL)',
  PA: 'Pennsylvania (PA)',
  OH: 'Ohio (OH)',
  GA: 'Georgia (GA)',
  NC: 'North Carolina (NC)',
  MI: 'Michigan (MI)',
  NJ: 'New Jersey (NJ)',
  VA: 'Virginia (VA)',
  AZ: 'Arizona (AZ)',
  MA: 'Massachusetts (MA)',
  TN: 'Tennessee (TN)',
  IN: 'Indiana (IN)',
  MO: 'Missouri (MO)',
  MD: 'Maryland (MD)',
  WI: 'Wisconsin (WI)',
  CO: 'Colorado (CO)',
  MN: 'Minnesota (MN)',
  SC: 'South Carolina (SC)',
  AL: 'Alabama (AL)',
  LA: 'Louisiana (LA)',
  KY: 'Kentucky (KY)',
  OR: 'Oregon (OR)',
  OK: 'Oklahoma (OK)',
  CT: 'Connecticut (CT)',
  UT: 'Utah (UT)',
  IA: 'Iowa (IA)',
  NV: 'Nevada (NV)',
  AR: 'Arkansas (AR)',
  MS: 'Mississippi (MS)',
  KS: 'Kansas (KS)',
  NM: 'New Mexico (NM)',
  NE: 'Nebraska (NE)',
  WV: 'West Virginia (WV)',
  ID: 'Idaho (ID)',
  HI: 'Hawaii (HI)',
  NH: 'New Hampshire (NH)',
  ME: 'Maine (ME)',
  MT: 'Montana (MT)',
  RI: 'Rhode Island (RI)',
  SD: 'South Dakota (SD)',
  ND: 'North Dakota (ND)',
  AK: 'Alaska (AK)',
  DC: 'District of Columbia (DC)',
  VT: 'Vermont (VT)',
  WY: 'Wyoming (WY)',
  PR: 'Puerto Rico (PR)',
};

export const US_STATES = {
  DE: 'Delaware (DE)',
  CA: 'California (CA)',
  TX: 'Texas (TX)',
  NY: 'New York (NY)',
  FL: 'Florida (FL)',
  WA: 'Washington (WA)',
  IL: 'Illinois (IL)',
  PA: 'Pennsylvania (PA)',
  OH: 'Ohio (OH)',
  GA: 'Georgia (GA)',
  NC: 'North Carolina (NC)',
  MI: 'Michigan (MI)',
  NJ: 'New Jersey (NJ)',
  VA: 'Virginia (VA)',
  AZ: 'Arizona (AZ)',
  MA: 'Massachusetts (MA)',
  TN: 'Tennessee (TN)',
  IN: 'Indiana (IN)',
  MO: 'Missouri (MO)',
  MD: 'Maryland (MD)',
  WI: 'Wisconsin (WI)',
  CO: 'Colorado (CO)',
  MN: 'Minnesota (MN)',
  SC: 'South Carolina (SC)',
  AL: 'Alabama (AL)',
  LA: 'Louisiana (LA)',
  KY: 'Kentucky (KY)',
  OR: 'Oregon (OR)',
  OK: 'Oklahoma (OK)',
  CT: 'Connecticut (CT)',
  UT: 'Utah (UT)',
  IA: 'Iowa (IA)',
  NV: 'Nevada (NV)',
  AR: 'Arkansas (AR)',
  MS: 'Mississippi (MS)',
  KS: 'Kansas (KS)',
  NM: 'New Mexico (NM)',
  NE: 'Nebraska (NE)',
  WV: 'West Virginia (WV)',
  ID: 'Idaho (ID)',
  HI: 'Hawaii (HI)',
  NH: 'New Hampshire (NH)',
  ME: 'Maine (ME)',
  MT: 'Montana (MT)',
  RI: 'Rhode Island (RI)',
  SD: 'South Dakota (SD)',
  ND: 'North Dakota (ND)',
  AK: 'Alaska (AK)',
  DC: 'District of Columbia (DC)',
  VT: 'Vermont (VT)',
  WY: 'Wyoming (WY)',
  PR: 'Puerto Rico (PR)',
  AB: 'Alberta (AB)',
  BC: 'British Columbia (BC)',
  MB: 'Manitoba (MB)',
  NB: 'New Brunswick (NB)',
  NL: 'Newfoundland and Labrador (NL)',
  NT: 'Northwest Territories (NT)',
  NS: 'Nova Scotia (NS)',
  NU: 'Nunavut (NU)',
  ON: 'Ontario (ON)',
  PE: 'Prince Edward Island (PE)',
  QC: 'Quebec (QC)',
  SK: 'Saskatchewan (SK)',
  YT: 'Yukon Territory (YT)',
  OTHER: 'Other / International',
};

export const CA_STATES = {
  AB: 'Alberta (AB)',
  BC: 'British Columbia (BC)',
  MB: 'Manitoba (MB)',
  NB: 'New Brunswick (NB)',
  NL: 'Newfoundland and Labrador (NL)',
  NT: 'Northwest Territories (NT)',
  NS: 'Nova Scotia (NS)',
  NU: 'Nunavut (NU)',
  ON: 'Ontario (ON)',
  PE: 'Prince Edward Island (PE)',
  QC: 'Quebec (QC)',
  SK: 'Saskatchewan (SK)',
  YT: 'Yukon Territory (YT)',
};

export const US_AND_INTL = {
  AK: 'Alaska (AK)',
  AL: 'Alabama (AL)',
  AR: 'Arkansas (AR)',
  AZ: 'Arizona (AZ)',
  CA: 'California (CA)',
  CO: 'Colorado (CO)',
  CT: 'Connecticut (CT)',
  DC: 'District of Columbia (DC)',
  DE: 'Delaware (DE)',
  FL: 'Florida (FL)',
  GA: 'Georgia (GA)',
  HI: 'Hawaii (HI)',
  IA: 'Iowa (IA)',
  ID: 'Idaho (ID)',
  IL: 'Illinois (IL)',
  IN: 'Indiana (IN)',
  KS: 'Kansas (KS)',
  KY: 'Kentucky (KY)',
  LA: 'Louisiana (LA)',
  MA: 'Massachusetts (MA)',
  MD: 'Maryland (MD)',
  ME: 'Maine (ME)',
  MI: 'Michigan (MI)',
  MN: 'Minnesota (MN)',
  MO: 'Missouri (MO)',
  MS: 'Mississippi (MS)',
  MT: 'Montana (MT)',
  NC: 'North Carolina (NC)',
  ND: 'North Dakota (ND)',
  NE: 'Nebraska (NE)',
  NH: 'New Hampshire (NH)',
  NJ: 'New Jersey (NJ)',
  NM: 'New Mexico (NM)',
  NV: 'Nevada (NV)',
  NY: 'New York (NY)',
  OH: 'Ohio (OH)',
  OK: 'Oklahoma (OK)',
  OR: 'Oregon (OR)',
  PA: 'Pennsylvania (PA)',
  PR: 'Puerto Rico (PR)',
  RI: 'Rhode Island (RI)',
  SC: 'South Carolina (SC)',
  SD: 'South Dakota (SD)',
  TN: 'Tennessee (TN)',
  TX: 'Texas (TX)',
  UT: 'Utah (UT)',
  VA: 'Virginia (VA)',
  VT: 'Vermont (VT)',
  WA: 'Washington (WA)',
  WI: 'Wisconsin (WI)',
  WV: 'West Virginia (WV)',
  WY: 'Wyoming (WY)',
  AUS: 'Australia (AUS)',
  AUT: 'Austria (AUT)',
  BEL: 'Belgium (BEL)',
  BRA: 'Brazil (BRA)',
  CAN: 'Canada (CAN)',
  CHN: 'China (CHN)',
  DNK: 'Denmark (DNK)',
  FIN: 'Finland (FIN)',
  FRA: 'France (FRA)',
  DEU: 'Germany (DEU)',
  HKG: 'Hong Kong (HKG)',
  IND: 'India (IND)',
  IDN: 'Indonesia (IDN)',
  IRL: 'Ireland (IRL)',
  ISR: 'Israel (ISR)',
  ITA: 'Italy (ITA)',
  JPN: 'Japan (JPN)',
  KOR: 'South Korea (KOR)',
  LUX: 'Luxembourg (LUX)',
  MYS: 'Malaysia (MYS)',
  MEX: 'Mexico (MEX)',
  NLD: 'Netherlands (NLD)',
  NZL: 'New Zealand (NZL)',
  NOR: 'Norway (NOR)',
  PHL: 'Philippines (PHL)',
  POL: 'Poland (POL)',
  PRT: 'Portugal (PRT)',
  RUS: 'Russia (RUS)',
  SGP: 'Singapore (SGP)',
  ZAF: 'South Africa (ZAF)',
  ESP: 'Spain (ESP)',
  SWE: 'Sweden (SWE)',
  CHE: 'Switzerland (CHE)',
  TWN: 'Taiwan (TWN)',
  THA: 'Thailand (THA)',
  TUR: 'Turkey (TUR)',
  GBR: 'United Kingdom (GBR)',
  VNM: 'Vietnam (VNM)',
};

export const STATES = {
  US: US_STATES,
  CA: CA_STATES,
};

export const getUSStateOptions = (stateNameAsValue = false) => {
  const countryStates = STRICTLY_US_STATES;
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateNameAsValue ? countryStates[stateId] : stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

// this will return [{ value: 'IL', label: 'Illinois' }]
export const getStateOptions = (country) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

export const getStateAndIntlOptions = () => {
  const countryStates = US_AND_INTL;
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

// this will return [{ value: 'Illinois', label: 'Illinois' }]
export const getStateNameOptions = (country) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return Object.values(countryStates).map((stateName) => ({
      value: stateName,
      label: stateName,
    }));
  }
  return [];
};

export const getStateNameByCode = (country, stateCode) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return countryStates[stateCode];
  }
  return stateCode;
};

export const getCodeByStateName = (country, stateName) => {
  const countryStates = STATES[country];
  if (countryStates && stateName) {
    return Object.keys(countryStates).find(
      (stateCode) =>
        countryStates[stateCode].toUpperCase() === stateName.toUpperCase() ||
        stateName.toUpperCase() === stateCode.toUpperCase(),
    );
  }
  return stateName;
};
