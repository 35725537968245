import {
  FinancingCommitment,
  FinancingRoundAttributes,
  FinancingCommitmentAttributes,
  InvestmentTermAttributes,
  FinancingRound,
  Ruv,
  RuvSummary,
  RuvInvestor,
  OfflineCommitmentAttributes,
} from '../../../graphql';
import {
  boolFormatter,
  directAndRuvStatusTags,
  statusFormatter,
} from '../../helpers/formatters';
import {
  formatCentsToDollars,
  formatDate,
  formatMoneyAbbreviated,
  formatTitle,
  formatToCents,
} from '../../helpers/utils';
import { processAdaptRowItems } from '../common/AdaptRowItem';
import {
  COMMIT_STATUS,
  INSTRUMENT_TYPE,
  INVESTOR_SOURCE,
  ROUND_STATUS,
  ROUND_TYPE_OPTIONS,
  ROUND_TYPES,
  RUV_STATUSES,
} from './constants';
import { CommitmentType } from './types';
import { signedSubStatusFormatter } from './formatters';

export const formatRoundAttrs = (
  attrs: FinancingRoundAttributes & { customStageName?: string },
) => {
  const {
    customStageName,
    stageName,
    minimumCommitSizeCents,
    ...restAttrs
  } = attrs;

  return {
    ...restAttrs,
    stageName: stageName === 'Other' ? customStageName : stageName,
    minimumCommitSizeCents: formatToCents(minimumCommitSizeCents),
  };
};

export const formatInvestmentTermAttrs = (attrs: InvestmentTermAttributes) => ({
  ...attrs,
  investmentAmountCents: formatToCents(attrs.investmentAmountCents),
  valuationCents: formatToCents(attrs.valuationCents),
  discountPercent: Number(attrs.discountPercent),
  interestRate: Number(attrs.interestRate),
  hasMfn: attrs.docTemplateType === 'mfn',
});

export const formatCommitmentAttrs = (
  attrs: FinancingCommitmentAttributes | OfflineCommitmentAttributes,
) => ({
  ...attrs,
  commitmentAmountCents: formatToCents(attrs.commitmentAmountCents),
});

export const getCommitmentInvestorName = (commitment: FinancingCommitment) => {
  if (commitment) {
    const investorName =
      commitment.investmentEntity?.name ||
      commitment.investorSignatureBlock?.investorName;

    return (
      investorName ||
      commitment.entityName ||
      commitment.user?.name ||
      '[Unsigned]'
    );
  }

  return '[Entity Name TBD]';
};

export const RUV_STATUS_MAP: { [key: string]: string } = {
  no_action: 'Invited',
  viewed: 'Viewed',
  passed: 'Passed',
  signed: 'Signed',
  completed: 'Funds Received',
  canceled: 'Canceled',
};

export const RUV_STATUS_FILTER_MAP: { [key: string]: string } = {
  no_action: 'no_action',
  canceled: 'canceled',
  viewed: 'viewed',
  signed: 'signed',
  completed: 'fund_recieved',
};

const RUV_SUB_STATUS_MAP: { [key: string]: string } = {
  1: 'Pending KYC',
  2: 'Awaiting Funds',
  3: 'Awaiting Funds',
  4: 'Awaiting Funds',
  5: 'Awaiting Funds',
};

const SIGNED_SUB_STATUS_TOOLTIP_MAP = (
  lastReminderSentAt?: string,
  initiatedAt?: string,
  failedAt?: string,
): { [key: string]: string } => ({
  'Wire Pending': lastReminderSentAt
    ? `AngelList has not received a wire and last reminded the investor on ${formatDate(
        lastReminderSentAt,
        '-',
      )}. Wires are assigned 24 hours after they are received.`
    : 'AngelList has not received a wire. Wires are assigned 24 hours after they are received.',
  'ACH Pending': 'The investor has not initiated the ACH.',
  'ACH in Transit': initiatedAt
    ? `The investor has initiated the ACH on ${formatDate(
        initiatedAt,
        '-',
      )} and the funds should land in 3-4 business days.`
    : 'The investor has initiated the ACH on AngelList and the funds should land in 3-4 business days.',
  'ACH Under Compliance Review':
    'Additional information required from the investor. This may take 1-2 business days to process once submitted.',
  'ACH Failed': failedAt
    ? `The ACH failed on ${formatDate(
        failedAt,
        '-',
      )}. The investor can initiate a new ACH from their dashboard.`
    : 'The ACH failed. The investor can initiate a new ACH from their dashboard.',
  'USDC Pending': 'The investor has not initiated the USDC transfer.',
  'USDC in Transit': initiatedAt
    ? `The investor has initiated the USDC transfer on ${formatDate(
        initiatedAt,
        '-',
      )}.`
    : 'The investor has initiated the USDC transfer on AngelList.',
});

const SIGNED_SUB_STATUS_LOADING: { [key: string]: string } = {
  2: 'Wire',
  3: 'ACH',
  4: 'USDC',
  5: 'Awaiting Funds',
};

export const ruvStatusFormatter = (params: {
  status: string;
  subStatus: string;
  signedSubStatus?: string;
  lastReminderSentAt?: string;
  initiatedAt?: string;
  failedAt?: string;
  isLoading?: boolean;
}) => {
  const {
    status,
    subStatus,
    signedSubStatus,
    lastReminderSentAt,
    initiatedAt,
    failedAt,
    isLoading,
  } = params;
  if (status === 'signed') {
    if (subStatus === '1' || (!signedSubStatus && !isLoading)) {
      return statusFormatter(
        RUV_SUB_STATUS_MAP[subStatus],
        'info',
        RUV_SUB_STATUS_MAP[subStatus],
      );
    }
    const statusText = isLoading
      ? SIGNED_SUB_STATUS_LOADING[subStatus]
      : signedSubStatus;
    return signedSubStatusFormatter(
      statusText,
      SIGNED_SUB_STATUS_TOOLTIP_MAP(lastReminderSentAt, initiatedAt, failedAt)[
        statusText
      ] || '',
      statusText === 'ACH Failed' ? 'danger' : 'info',
      isLoading,
    );
  }
  return statusFormatter(
    RUV_STATUS_MAP[status]?.replace(' ', '_'),
    null,
    RUV_STATUS_MAP[status],
  );
};

export const ruvStatusValue = (status: string, subStatus: string) => {
  if (status === 'signed') {
    return RUV_SUB_STATUS_MAP[subStatus];
  }
  return RUV_STATUS_MAP[status];
};

export const completedSteps = (ruvDetails: Ruv, details: FinancingRound) => {
  if (!details || !ruvDetails)
    return {
      bankInformation: false,
      confirmInvestments: false,
      equityAllocation: false,
      investmentDocs: false,
      payment: false,
    };

  const hasFundsCollected = ruvDetails.investors?.every(
    (investor) => investor.status !== 'signed',
  );
  return {
    bankInformation:
      !!ruvDetails.wireDetails &&
      !!details.ruvClosingStatus?.wireDetailsProvidedAt,
    confirmInvestments:
      (ruvDetails.status === 'Closing' || ruvDetails.status === 'Wired') &&
      (details.ruvClosingStatus?.allFundsCollectedAt || hasFundsCollected) &&
      !!details.ruvClosingStatus?.confirmedAmountRaisedAt,
    investmentDocs: !!details.ruvClosingStatus?.hasInvestmentDocs,
    payment: !!details.ruvClosingStatus?.confirmedTermsAt,
  };
};

export const getOpenSteps = (
  hasCompleted: any,
  raisedAmountFractional: number,
  overrideMinRaised: boolean,
) => ({
  bankInformation: !hasCompleted.bankInformation,
  confirmInvestments:
    hasCompleted.bankInformation && !hasCompleted.confirmInvestments,
  investmentDocs:
    hasCompleted.bankInformation &&
    hasCompleted.confirmInvestments &&
    !hasCompleted.investmentDocs,
  payment:
    hasCompleted.bankInformation &&
    hasCompleted.confirmInvestments &&
    hasCompleted.investmentDocs &&
    (raisedAmountFractional > 40_000_00 || overrideMinRaised) &&
    !hasCompleted.payment,
});

export const commitmentStatusValue = (
  status: string,
  instrumentType?: string,
  equityDocsCollected?: boolean,
) => {
  if (instrumentType === INSTRUMENT_TYPE.EQUITY) {
    if (status === COMMIT_STATUS.COMMITTED && equityDocsCollected) {
      return 'awaiting signature';
    }
    if (status === COMMIT_STATUS.SIGNED) {
      return 'awaiting funds';
    }
  }
  if (status === COMMIT_STATUS.FUND_RECIEVED) {
    return 'Funds Received';
  }
  if (status === COMMIT_STATUS.SIGNED) {
    return 'requires company signature';
  }
  return status;
};

export const investorStateValue = (
  state: string,
  commitment: CommitmentType,
  instrumentType?: string,
  equityDocsCollected?: boolean,
) => {
  if (commitment.source === INVESTOR_SOURCE.RUV) {
    const { subStatus } = commitment as RuvInvestor;
    return ruvStatusValue(state, subStatus);
  }

  if (instrumentType === INSTRUMENT_TYPE.EQUITY) {
    return commitmentStatusValue(
      state,
      INSTRUMENT_TYPE.EQUITY,
      equityDocsCollected,
    );
  }
  return commitmentStatusValue(state);
};

export const commitmentStatusFormatter = (
  status: string,
  instrumentType?: string,
  equityDocsCollected?: boolean,
) => {
  if (instrumentType === INSTRUMENT_TYPE.EQUITY) {
    if (status === COMMIT_STATUS.COMMITTED && equityDocsCollected) {
      return statusFormatter('awaiting_signature');
    }
    if (status === COMMIT_STATUS.SIGNED) {
      return statusFormatter('awaiting_funds');
    }
  }
  if (status === COMMIT_STATUS.FUND_RECIEVED) {
    return statusFormatter('Funds Received');
  }
  if (status === COMMIT_STATUS.SIGNED) {
    return statusFormatter('requires_company_signature');
  }
  return statusFormatter(status);
};

export const getRoundPercentage = (
  totalRaisedCents: number,
  totalCommitedCents: number,
  targetSizeCents: number,
) => {
  const maxSize = targetSizeCents
    ? Math.max(targetSizeCents, totalCommitedCents)
    : totalCommitedCents;

  let totalRaisedPercentage = 0;
  if (maxSize && totalRaisedCents) {
    totalRaisedPercentage = Number(
      ((totalRaisedCents / maxSize) * 100).toFixed(2),
    );
  }
  let totalCommitedPercentage = 0;
  if (maxSize && totalCommitedCents) {
    totalCommitedPercentage = Number(
      ((totalCommitedCents / maxSize) * 100).toFixed(2),
    );
  }

  return {
    totalRaisedPercentage,
    totalCommitedPercentage,
  };
};

export const getRoundStats = (
  financingRound: FinancingRound,
  ruvDetails: Ruv,
  commitments: FinancingCommitment[],
) => {
  let totalCommitedCents = financingRound.totalCommited?.fractional || 0;
  let totalRaisedCents = financingRound.totalRaised?.fractional || 0;
  const targetSizeCents = financingRound?.targetRoundSize?.fractional || 0;
  const totalInvestorsList = [];
  if (commitments.length) {
    totalInvestorsList.push(`${commitments.length} direct`);
  }

  let directRaised = Number(financingRound.totalRaised?.dollars);
  let directCommitted = Number(financingRound.totalCommited?.dollars);

  const ruvRaised = Number(ruvDetails?.raised?.dollars || 0);
  const ruvCommitted = Number(ruvDetails?.committed?.dollars || 0);

  const ruvInvestorsWithFunds =
    ruvDetails?.investors?.filter(
      (investor) => investor.status === 'completed',
    ) || [];

  if (ruvDetails) {
    const closingCommit = commitments.find((c) => c.isRuvClosingCommitment);

    if (closingCommit) {
      if (closingCommit.state === COMMIT_STATUS.FUND_RECIEVED) {
        directCommitted -= Number(closingCommit.commitmentAmount.dollars);
        directRaised -= Number(closingCommit.commitmentAmount.dollars);
      } else {
        directCommitted -= Number(closingCommit.commitmentAmount.dollars);
        totalRaisedCents += closingCommit.commitmentAmount.fractional || 0;
      }
    } else {
      totalCommitedCents += ruvDetails.committed?.fractional || 0;
      totalRaisedCents += ruvDetails.raised?.fractional || 0;
    }

    const totalDirectInvestors = commitments.length; // for direct investors
    const totalRuvInvestorsWithFunds = ruvInvestorsWithFunds.length; // for ruv investors
    const totalInvestors = totalDirectInvestors + totalRuvInvestorsWithFunds; // total investors

    const {
      totalRaisedPercentage,
      totalCommitedPercentage,
    } = getRoundPercentage(
      totalRaisedCents,
      totalCommitedCents,
      targetSizeCents,
    );

    return {
      totalRaisedPercentage,
      totalCommitedPercentage,
      totalInvestors,
      targetSize: formatCentsToDollars(targetSizeCents),
      totalRaised: formatCentsToDollars(totalRaisedCents),
      totalCommited: formatCentsToDollars(totalCommitedCents),
      directRaised,
      directCommitted,
      ruvRaised,
      ruvCommitted,
    };
  }

  // This calculates the percentages of total funds raised and committed relative to the target round size
  const { totalRaisedPercentage, totalCommitedPercentage } = getRoundPercentage(
    totalRaisedCents,
    totalCommitedCents,
    targetSizeCents,
  );

  return {
    totalRaisedPercentage,
    totalCommitedPercentage,
    totalInvestors: commitments.length, // this one is for direct investors
    targetSize: formatCentsToDollars(targetSizeCents),
    totalRaised: formatCentsToDollars(totalRaisedCents),
    totalCommited: formatCentsToDollars(totalCommitedCents),
    directRaised,
    directCommitted,
    ruvRaised: 0,
    ruvCommitted: 0,
  };
};

export const getTotalRowItems = (
  suffix: string,
  directValue: string,
  ruvValue?: string,
  loading?: boolean,
) => {
  const items = [
    {
      label: `${suffix} directly`,
      suffixLabel: directValue,
    },
  ];
  if (ruvValue) {
    items.push({
      label: `${suffix} via RUV`,
      suffixLabel: ruvValue,
    });
  }
  return processAdaptRowItems(items, { compact: true, loading });
};

export const getTransferDetailsRowItems = (
  wireInitiatedAt: string | null,
  paymentSentAt: string | null,
  loading?: boolean,
) => {
  const items = [
    {
      label: 'Money raised',
      suffixLabel: wireInitiatedAt
        ? `Wire initiated ${formatDate(wireInitiatedAt)}`
        : 'No wire initiated',
    },
    {
      label: 'Payment',
      suffixLabel: paymentSentAt
        ? `ACH pulled ${formatDate(paymentSentAt)}`
        : 'No payment initiated',
    },
  ];
  return processAdaptRowItems(items, { compact: true, loading });
};

const getTotalRuvValues = (ruvs: RuvSummary[]) => {
  let totalRuvRaisedCents = 0;
  let totalRuvCommitedCents = 0;
  let totalRuvInvestors = 0;

  ruvs.forEach((ruv) => {
    totalRuvCommitedCents += ruv.committed?.fractional || 0;
    totalRuvRaisedCents += ruv.raised?.fractional || 0;
    totalRuvInvestors += ruv.numInvestors || 0;
  });

  return { totalRuvCommitedCents, totalRuvRaisedCents, totalRuvInvestors };
};

const getTotalDirectValue = (rounds: FinancingRound[]) => {
  let totalDirectCommitedCents = 0;
  let totalDirectRaisedCents = 0;
  let totalDirectInvestors = 0;

  rounds.forEach((financingRound) => {
    totalDirectCommitedCents += financingRound.totalCommited?.fractional || 0;
    totalDirectRaisedCents += financingRound.totalRaised?.fractional || 0;
    totalDirectInvestors += financingRound.totalInvestors || 0;

    const closingCommit = financingRound.ruvClosingCommitment;
    if (closingCommit) {
      if (closingCommit.state === COMMIT_STATUS.FUND_RECIEVED) {
        totalDirectRaisedCents -= closingCommit.commitmentAmount.fractional;
        totalDirectCommitedCents -= closingCommit.commitmentAmount.fractional;
      } else {
        totalDirectCommitedCents -= closingCommit.commitmentAmount.fractional;
      }
    }
  });

  return {
    totalDirectCommitedCents,
    totalDirectRaisedCents,
    totalDirectInvestors,
  };
};

export const getAllRoundStats = (
  rounds: FinancingRound[],
  ruvsSummary: RuvSummary[],
) => {
  const {
    totalDirectCommitedCents,
    totalDirectRaisedCents,
    totalDirectInvestors,
  } = getTotalDirectValue(rounds);
  const {
    totalRuvRaisedCents,
    totalRuvCommitedCents,
    totalRuvInvestors,
  } = getTotalRuvValues(ruvsSummary);

  const totalRaisedCents = totalDirectRaisedCents + totalRuvRaisedCents;
  const totalCommitedCents = totalDirectCommitedCents + totalRuvCommitedCents;

  return {
    totalDirectInvestors,
    totalRuvInvestors,
    totalRuvRaised: formatCentsToDollars(totalRuvRaisedCents),
    totalRuvCommited: formatCentsToDollars(totalRuvCommitedCents),
    totalDirectRaised: formatCentsToDollars(totalDirectRaisedCents),
    totalDirectCommited: formatCentsToDollars(totalDirectCommitedCents),
    totalRaised: formatCentsToDollars(totalRaisedCents),
    totalCommited: formatCentsToDollars(totalCommitedCents),
  };
};

export const roundStatusFormatter = (status: string) => {
  if (status === 'created') {
    return statusFormatter('Raising', 'info');
  }
  if (status === 'closed') {
    return statusFormatter('Closed', 'success');
  }
  return statusFormatter(status);
};

const configureStatusAndIntent = (status: string, isRuv: boolean) => {
  let roundStatus = status;
  let intent = null;

  if (isRuv) {
    if (status === RUV_STATUSES.IN_REVIEW || status === RUV_STATUSES.CLOSING) {
      intent = 'pending';
    } else if (status === RUV_STATUSES.RAISING) {
      intent = 'complete';
    } else if (status === RUV_STATUSES.WIRED) {
      intent = 'postComplete';
    } else if (status === RUV_STATUSES.CANCELLED) {
      intent = 'canceled';
    }
  } else if (status === ROUND_STATUS.CREATED) {
    roundStatus = 'Raising';
    intent = 'complete';
  } else if (status === ROUND_STATUS.CLOSED) {
    roundStatus = 'Closed';
    intent = 'postComplete';
  }

  return { roundStatus, intent };
};

export const directAndRuvStatusFormatter = (
  ruvStatus: string,
  directStatus: string,
) => {
  const {
    roundStatus: ruvRoundStatus,
    intent: ruvIntent,
  } = configureStatusAndIntent(ruvStatus, true);
  const {
    roundStatus: directRoundStatus,
    intent: directIntent,
  } = configureStatusAndIntent(directStatus, false);

  return directAndRuvStatusTags(
    ruvRoundStatus,
    directRoundStatus,
    ruvIntent,
    directIntent,
  );
};

export const roundCsvFormatter = (status: string) => {
  if (status === 'created') {
    return 'Raising';
  }
  return formatTitle(status);
};

export const isRaiseSignup = (
  genericDashboard: string,
  genericProducts: string[],
) =>
  genericDashboard === 'fundraising' ||
  (genericDashboard === 'activate' && genericProducts.includes('fundraising'));

export const getRoundSeparatedListItems = (financingRound: FinancingRound) => {
  const items: string[] = [];

  const { investmentTerm } = financingRound;
  const { safeType, instrumentType, docTemplateType } = investmentTerm || {};

  const isSafe = instrumentType === INSTRUMENT_TYPE.SAFE;
  const isPreMoney = safeType === 'pre_money';
  const hasCap =
    docTemplateType === 'val_cap' || docTemplateType === 'val_cap_discount';
  const hasDiscount =
    docTemplateType === 'discount' || docTemplateType === 'val_cap_discount';

  if (isSafe) {
    if (hasCap && investmentTerm?.valuation) {
      const capValue = formatMoneyAbbreviated(
        investmentTerm?.valuation.dollars,
        investmentTerm?.valuation.currency,
      );
      items.push(`${capValue} valuation cap`);
    }
    if (hasDiscount) {
      if (investmentTerm?.discountPercent) {
        items.push(`${investmentTerm?.discountPercent}% discount`);
      } else {
        items.push('No discount');
      }
    }
    if (isPreMoney) {
      if (boolFormatter(investmentTerm?.includesProRata)) {
        items.push('Pro-rata');
      } else {
        items.push('No pro-rata');
      }
    }
  } else if (investmentTerm?.valuation) {
    const capValue = formatMoneyAbbreviated(
      investmentTerm?.valuation.dollars,
      investmentTerm?.valuation.currency,
    );
    items.push(`${capValue} valuation`);
  }

  return items;
};

export const canShowRuv = (instrumentType: string, alRuvId: string) =>
  !!alRuvId &&
  [INSTRUMENT_TYPE.SAFE, INSTRUMENT_TYPE.EQUITY, INSTRUMENT_TYPE.NOTE].includes(
    instrumentType,
  );

export const canAddOfflineCommitment = (
  instrumentType: string,
  allowDirectInvestment: boolean,
) =>
  (instrumentType === INSTRUMENT_TYPE.SAFE ||
    instrumentType === INSTRUMENT_TYPE.NOTE) &&
  allowDirectInvestment;

export const canAddEquityOfflineCommitment = (instrumentType: string) =>
  instrumentType === INSTRUMENT_TYPE.EQUITY;

export const getRoundTypeOptions = ({
  alRuvId,
  allowDirectInvestment,
  instrumentType,
  hasDirectCommitments,
  hasRuvCommitments,
  hasEquityRounds,
}: {
  alRuvId: string;
  allowDirectInvestment: boolean;
  instrumentType: string;
  hasDirectCommitments: boolean;
  hasRuvCommitments: boolean;
  hasEquityRounds: boolean;
}) => {
  // Clone and modify options based on certain conditions
  const options = ROUND_TYPE_OPTIONS.map((option) => {
    const isDirectOrCombined =
      option.value === ROUND_TYPES.DIRECT ||
      option.value === ROUND_TYPES.DIRECT_AND_RUV;

    if (
      alRuvId &&
      !allowDirectInvestment &&
      isDirectOrCombined &&
      ['equity', 'note'].includes(instrumentType) &&
      !hasEquityRounds
    ) {
      return { ...option, isDisabled: true };
    }

    return option;
  });

  // Filter options based on commitments
  if (!hasDirectCommitments && !hasRuvCommitments) {
    return options;
  }

  if (hasDirectCommitments && hasRuvCommitments) {
    return options.filter(
      (option) => option.value === ROUND_TYPES.DIRECT_AND_RUV,
    );
  }

  if (hasDirectCommitments) {
    return options.filter(
      (option) =>
        option.value === ROUND_TYPES.DIRECT ||
        option.value === ROUND_TYPES.DIRECT_AND_RUV,
    );
  }

  // If only RUV commitments exist
  return options.filter(
    (option) =>
      option.value === ROUND_TYPES.RUV ||
      option.value === ROUND_TYPES.DIRECT_AND_RUV,
  );
};

export const MIN_VAUALTION = 100_000;

export const validateValuation = (value: any) => {
  // If is already a number, do nothing, else convert to number
  const numericValue =
    typeof value === 'number' ? value : Number(value.replace(/,/g, '')); // Remove commas and convert to a number
  return numericValue >= MIN_VAUALTION || 'Valuation must be at least $100,000';
};
